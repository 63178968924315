import styles from "./FifthScreen.module.css"
import sharon from "../images/שרון בן דוד.png"
import BasicAccordion from "../components/questions/Akordion"

const FifthScreen=()=>{
   

return <>
<div className={styles.background}>
<BasicAccordion/>

</div>

</>


}
export default FifthScreen